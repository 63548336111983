import {Box} from "@mui/material";
import curriculum from '../assets/curriculum.png';
import nido from '../assets/nido.png';
import preschool from '../assets/preschool.png';
import primary from '../assets/primary.png';
import scaffoldedGroups from '../assets/scaffolded-groups.png';

import ContentTile from "../assets/components/ContentTile.tsx";
import ResponsiveAppBar from "../assets/components/ResponsiveAppBar.tsx";
import ContactFooter from "./ContactFooter.tsx";
import Footer from "./Footer.tsx";

function Academics() {
    return (
        <>
            <Box sx={{}}>
                <ResponsiveAppBar/>

                <ContentTile
                    id={"curriculum"}
                    header={"CURRICULUM"}
                    subtitle={"Inquiry Based Learning"}
                    body={`To foster creativity and innovation, our curriculum incorporates a variety of cultural experiences in conjunction with the traditional academic disciplines of math, literacy, and science. These areas include visual art, music appreciation, cooking, and gardening, all enjoyed in developmentally appropriate environments where children gain the confidence to question, explore, and learn. 
                        \\n\\n
                        Classes engage in long-term, in-depth projects of topics that capture children’s imagination. Through these explorations, children develop critical thinking, problem-solving skills, and a deep understanding of the subject matter. True innovation stems from the ability to think both creatively and critically, to invent where there is no readily available solution, to transform failure into opportunity, to improve on mistakes and refine processes, and to redefine the status quo of accepted methods, products, and whole markets.  Communication skills are developed through meaningful interactions and discussions with educators and peers, as children engage in hands-on activities, research, discussions, and creative expression.`}
                    ImageComponent={<img src={curriculum} alt={'Curriculum'}/>}

                />
                <ContentTile
                    id={"nido"}
                    header={"NIDO"}
                    subtitle={"Ages 1-3"}
                    body={`Nido, which means “nest” in Italian, perfectly describes the nurturing setting for our youngest learners. At this stage, small children are seen as explorers and scientists. The multi-sensory navigations of their environment, the spontaneity of observing, the desire to experience life with and through their body and the pleasure of enjoying human development in a natural way are the focus of our curriculum.  
                        \\n\\n
                        A successful early learning environment fosters a community marked by meaningful relationships among young children and the adults in their lives—parents, teachers, and caregivers. These connections flourish through shared learning experiences in nature and through emotional connections. The community cultivates and sustains developmentally appropriate conditions that enable children to optimize their individual potential. From this perspective, the cultivation of purposeful practices, presenting thoughtfully prepared environments that challenge children, emerges as the ideal blueprint for the development of positive school experiences for children and their families.`}
                    ImageComponent={<img src={nido} alt={'Nido'}/>}
                    reverse={true}
                />
                <ContentTile
                    id={"preschool"}
                    header={"PRESCHOOL"}
                    subtitle={"Ages 3-5"}
                    body={`As children transition from the Nido program to our Preschool program, they enter a phase of the curriculum that builds upon on their expanded vocabulary. Collaboration and communication become the cornerstones of all activities. Within this framework, children are prompted to engage in small peer groups, fostering the exploration, design, and presentation of their projects. This approach encourages learners to formulate hypotheses, investigate processes, and draw conclusions, empowering them to articulate and defend ideas, building confidence in their ability to formulate individual opinions and become the authors of their own learning journeys.  
                        \\n\\n
                        Explorations are based upon long term projects throughout the year, allowing students to build upon previously mastered concepts, deepening their ability to learn. Through these interdisciplinary projects, children engage in foundational learning skills such as math, science, social studies, and language arts in meaningful ways.`}
                    ImageComponent={<img src={preschool} alt={'Preschool'}/>}

                />
                <ContentTile
                    id={"primary"}
                    header={"PRIMARY"}
                    subtitle={"Grades Kindergarten - 5th"}
                    body={`Our Primary School program is designed to deliver the traditional academic disciplines through a S.T.E.A.M. framework. The curriculum is centered on interdisciplinary projects that create engaging learning experiences incorporating the traditional academic principles through hands on, open-ended explorations. Through the use of instructional scaffolding, which allows students of mixed grade levels to interact and work collaboratively, students excel past the confinements and limits set by the traditional grade level system.  
                        \\n\\n
                        In order to create this adaptive learning environment we offer limited class sizes and a closely followed teacher/student ratio. Each student in the program receives a customized education plan, known as an Individualized Learning Portfolio, that creates a personalized map for each student’s academic strengths, weaknesses, and goals.`}
                    ImageComponent={<img src={primary} alt={'Primary'}/>}
                    reverse={true}
                />
                <ContentTile
                    id={"scaffolded-groups"}
                    header={"SCAFFOLDED GROUPS"}
                    subtitle={"Zone of Proximal Development"}
                    body={`Respect for the diverse levels of experience and expertise among children and affording each child the opportunity to progress at their own pace is a fundamental learning process that is central to our program. By incorporating diversity of ages as a core strategy, the levels of expertise of children act as an additional strength to the learning process. Recognizing that children are adept at both eliciting and responding to social interaction, our approach fosters intricate social dynamics, akin to the complexity observed in language acquisition.`}
                    ImageComponent={<img src={scaffoldedGroups} alt={'Scaffolded Groups'}/>}
                />
                <ContactFooter/>
                <Footer/>
            </Box>
        </>
    );
}

export default Academics

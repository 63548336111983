import React, {ReactNode, useRef} from 'react';
import {Box, Typography} from '@mui/material';
import 'swiper/css';
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, Navigation, Pagination} from "swiper/modules";
import hero1 from "../hero/1.jpg";
import hero2 from "../hero/2.jpg";
import hero3 from "../hero/3.jpg";
import hero4 from "../hero/4.jpg";

interface ContentTileProps {
    id?: string;
    header: string;
    subtitle: string;
    body: string;
    ImageComponent: ReactNode;
    reverse?: boolean;
    isSlideshow?: boolean;
}

const ContentTile: React.FC<ContentTileProps> = ({id, header, subtitle, body, ImageComponent, reverse = false, isSlideshow}) => {
    const bodyContent = body.split('\\n').map((line, index, array) => (
        <React.Fragment key={index}>
            {line}
            {index < array.length - 1 && <br/>}
        </React.Fragment>
    ));


    const progressCircle = useRef(null);
    const progressContent = useRef(null);


    const onAutoplayTimeLeft = (_s: unknown, time: number, progress: number) => {
        if (progressCircle.current && progressContent.current) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            progressCircle.current['style'].setProperty('--progress', 1 - progress);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            progressContent.current['textContent'] = `${Math.ceil(time / 1000)}s`;
        }
    };

    return (
        <Box className={'tile'} id={id}>
            <Typography className={'header'}>{header}</Typography>
            <Typography className={'subtitle'}>{subtitle}</Typography>
            <br/>

            <Box sx={{textAlign: {sm: `${reverse ? 'left' : 'right'}`,}}}>
                <Box className={`image ${reverse ? 'right' : 'left'}`}>
                    {isSlideshow == true ? <div className={'img'} style={{position:'relative'}}>
                        <Swiper pagination={{type: 'progressbar',}} navigation={true}
                                modules={[Autoplay, Pagination, Navigation]} loop={true}
                                autoplay={{delay: 5000, disableOnInteraction: false,}}
                                onAutoplayTimeLeft={onAutoplayTimeLeft}
                        >
                            <SwiperSlide><img className={'ignore'} src={hero1} alt={"classroom 1"}/> </SwiperSlide>
                            <SwiperSlide><img className={'ignore'} src={hero2} alt={"atelier"}/> </SwiperSlide>
                            <SwiperSlide><img className={'ignore'} src={hero3} alt={"multi purpose room"}/>
                            </SwiperSlide>
                            <SwiperSlide><img className={'ignore'} src={hero4} alt={"hallway"}/> </SwiperSlide>
                        </Swiper>
                        <div className="autoplay-progress" slot="container-end">
                            <svg viewBox="0 0 48 48" ref={progressCircle}>
                                <circle cx="24" cy="24" r="20"></circle>
                            </svg>
                            <span ref={progressContent}></span>
                        </div>
                    </div> : ImageComponent}
                </Box>
                <Typography className={'body'}>{bodyContent}</Typography>

            </Box>
        </Box>
    );
};

export default ContentTile;

import {Box, Link, Typography} from "@mui/material";

import ResponsiveAppBar from "../assets/components/ResponsiveAppBar.tsx";
import ContactFooter from "./ContactFooter.tsx";
import Footer from "./Footer.tsx";
import DoeEthicalConduct from "../assets/forms/DOE_Ethical_Conduct_24.pdf"

function Admissions() {
    return (
        <>
            <Box sx={{}}>
                <ResponsiveAppBar/>

                <Box className={'tile'} sx={{minHeight: '100vh'}}>
                    <Typography className={'header'}>ADMISSIONS & SCHOLARSHIPS</Typography>
                    <br/>
                    <Typography className={'body'} sx={{textAlign: 'left'}}>Prodigy Learning offers a rolling admissions
                        process. Interested families may send an email to their local school to learn about possible
                        openings and schedule a tour. Applicants are considered for a class if space is available or for
                        a position on the wait list if a class is full.</Typography>
                    <br/>
                    <Typography className={'body'} sx={{textAlign: 'left'}}>To learn more about our programs, tuition,
                        and schedule a tour, please contact our admissions department.
                    </Typography>
                    <br/>
                    <Typography className={'body'} sx={{textAlign: 'left'}}>
                        <Link href={"mailto:admissions@prodigylearn.com"}>Admissions@prodigylearn.com</Link>
                        <br/>
                        <Link href={"tel:9543631248"}>954-363-1248</Link>
                    </Typography>
                    <br/>
                    <Typography className={'body'} sx={{textAlign: 'left'}}>Please note: For all campus tours both
                        guardians and prospective students are required to be present.
                    </Typography>
                    <br/>
                    <br/>
                    <Box sx={{display: 'flex', flexWrap: 'wrap',}}>
                        <Box sx={{textAlign: 'left'}}>
                            <Typography className={'body'}
                                        style={{fontWeight: 900}}>Step-Up for Students </Typography>
                            <Typography className={'body'}>We are proud to partner with Step-Up for Students to offer
                                Florida based families scholarships for children school ages K-5.</Typography>
                            <Link className='body' href={"https://www.stepupforstudents.org/"}>To Learn More Visit Their
                                Site</Link>
                            <br/>
                        </Box>
                    </Box>

                    <br/>
                    <Box sx={{textAlign: 'left',}}>

                        <Typography className={'body'}
                                    style={{fontWeight: 900}}>Florida Voluntary
                            PreKindergarten Program (VPK)</Typography>
                        <Typography className={'body'}>We are proud to partner with the state of Florida’s OEL to offer
                            VPK vouchers to students in the Pre-K 4 year. </Typography>
                        <Link className='body'
                              href={"https://www.fldoe.org/schools/early-learning/parents/vpk-parents.stml"}>To Learn
                            More Visit Their Site</Link>
                        <br/>
                    </Box>
                </Box>

                <Box className={'tile'} sx={{minHeight: '50vh'}}>
                    <Typography className={'header'}>FORMS</Typography>

                    <Box sx={{display: 'flex', flexWrap: 'wrap',}}>
                        <Box sx={{textAlign: 'left'}}>
                            <Typography className={'body'}
                                        style={{fontWeight: 900}}>DOE Ethical Conduct </Typography>
                            <Typography className={'body'}>Adapted from the Code of Ethics of the Education Profession
                                in Florida and Principles of Professional Conduct for the Education Profession in
                                Florida.</Typography>
                            <Link className='body' href={DoeEthicalConduct}>View Standards of Ethical Conduct</Link>
                            <br/>
                        </Box>
                    </Box>
                </Box>

                <ContactFooter/>
                <Footer/>
            </Box>
        </>
    );
}

export default Admissions

import {Box, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import flare from "../assets/flare/rip-lets-talk.svg";
import grass from "../assets/flare/lets-talk-grass.png";
import tree from "../assets/flare/lets-talk-tree.png";
import owl from "../assets/flare/lets-talk-owl.svg";

function ContactFooter() {
    return (
        <>
            <img style={{
                width: '100vw',
                objectFit: 'cover',
                maxHeight: 125,
                objectPosition: 'top',
            }} src={flare} alt={"ripped paper"}/>
            <Box sx={{
                backgroundColor: '#f0e3d7',
                minHeight: 200,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                position: 'relative',
            }}>
                <Typography variant={'h3'} sx={{fontWeight: 'bold', mt: 4, mb:4,}}> Ready to learn more?</Typography>
                <Button sx={{m: 5, fontSize: '1.5rem', borderRadius: '15px',zIndex:5,}} variant={"contained"}
                        href={"/lets-talk"}>Let's Talk!</Button>
                <Box sx={{position: 'relative',width:'fit-content',}}>
                    <img style={{
                        maxWidth:'100vw',
                        objectFit: 'contain',
                        maxHeight: 125,
                        objectPosition: 'top',
                    }} src={grass} alt={"grass"}/>
                    <Box component={"img"} sx={{
                        position:'absolute',
                        bottom:50,
                        left:0,
                       height:200,
                    }} src={tree} alt={"tree"}/>
                    <Box component={"img"} sx={{
                        position:'absolute',
                        bottom:50,
                        right:50,
                        height:75,
                    }} src={owl} alt={"prodigy learning owl"}/>
                </Box>
            </Box>
        </>
    );
}

export default ContactFooter

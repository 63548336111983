import logo from '../logo-wide-dark.svg';
import {Nav, Navbar, NavDropdown} from 'react-bootstrap';
import {useEffect} from "react";
import {Box} from "@mui/material";
import React from 'react';
import {useLocation, useNavigate} from "react-router-dom";

const pages = [
    {
        title: 'OUR STORY',
        link: '/',
        children: [
            {title: 'OUR APPROACH', link: '/#our-approach',},
            {title: 'LEARNING ENVIRONMENT', link: '/#learning-environment',},
            {title: 'FAMILY ENGAGEMENT', link: '/#family-engagement',},
            // {title: 'ACCREDITATION', link: '/#accreditation',},
        ],
    },
    {
        title: 'ACADEMICS', link: '/academics',
        children: [
            {title: 'CURRICULUM', link: '/academics/#curriculum',},
            {title: 'NIDO', link: '/academics/#nido',},
            {title: 'PRESCHOOL', link: '/academics/#preschool',},
            {title: 'PRIMARY', link: '/academics/#primary',},
            {title: 'SCAFFOLDED GROUPS', link: '/academics/#scaffolded-groups',},
        ]
    },
    {
        title: 'ARTS & LABS', link: '/arts-labs',
        children: [
            {title: 'ART LAB', link: '/arts-labs/#art-lab',},
            {title: 'CULINARY LAB', link: '/arts-labs/#culinary-lab',},
            {title: 'LANGUAGE IMMERSION', link: '/arts-labs/#language-immersion',},
            {title: 'MINDFULNESS', link: '/arts-labs/#mindfulness',},
        ]
    },
    {title: 'ADMISSIONS', link: '/admissions', children: []},
    {title: 'OUR SCHOOLS', link: '/our-schools', children: []},
    {title: "LET'S TALK", link: '/lets-talk', children: []},
];

function ResponsiveAppBar() {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const oParam = queryParams.get('o');

        if (oParam) {
            localStorage.setItem('o', oParam);
            queryParams.delete('o');

            navigate(`${location.pathname}?${queryParams.toString()}`, { replace: true });
        }
    }, [location, navigate]);

    useEffect(() => {
        if (window.location.hash != '') {
            scrollIntoView(window.location.hash.replace('#', ''));
        }
    });
    const scrollIntoView = (location: string) => {
        setTimeout(() => {
            document.getElementById(location)!.scrollIntoView();
            const currentUrl = window.location.href;
            const updatedUrl = currentUrl.split('#')[0];

            history.pushState(null, '', updatedUrl);
        }, 250);

    }

    return (<Navbar expand="md" sticky={"top"} style={{backgroundColor: '#EEECDF'}}>
        <Navbar.Brand href="/">
            <img src={logo} alt={"Prodigy Learning"}
                 style={{marginLeft: 40, width: 185,}}/>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" style={{marginRight: 20,}}/>
        <Navbar.Collapse id="basic-navbar-nav" className={"basic-navbar-nav"}>
            <Nav className="justify-content-end align-items-center" style={{width: "100%"}}>
                {pages.map((page, index) => {
                    if (page.children.length > 0) {
                        return <React.Fragment key={`${index}-nav-wrapper`}><NavDropdown key={`${index}-nav-dropdown`} title={page.title} id="basic-nav-dropdown" style={{fontWeight: 'bold'}}>
                            {page.children.map((child) => {
                                return <NavDropdown.Item href={child.link} key={child.link}>{child.title}</NavDropdown.Item>
                            })}
                        </NavDropdown>
                            {index < pages.length - 1 ?
                                <Box sx={{
                                    color: 'black',
                                    marginLeft: 2,
                                    marginRight: 2,
                                    display: {xs: 'none', lg: 'block',}
                                }}>|</Box> : null}
                        </React.Fragment>
                    } else {
                        return <React.Fragment key={`${index}-nav-wrapper`}><Nav.Link href={page.link} style={{fontWeight: 'bold'}} key={page.link}>{page.title}</Nav.Link>
                            {index < pages.length - 1 ?
                                <Box sx={{
                                    color: 'black',
                                    marginLeft: 2,
                                    marginRight: 2,
                                    display: {xs: 'none', lg: 'block',}
                                }}>|</Box> : null}
                        </React.Fragment>
                    }

                })}
            </Nav>
        </Navbar.Collapse>

    </Navbar>)
}

export default ResponsiveAppBar;
import {Box, Button, Link, TextField} from "@mui/material";

import ResponsiveAppBar from "../assets/components/ResponsiveAppBar.tsx";
import ContactFooter from "./ContactFooter.tsx";
import Footer from "./Footer.tsx";
import {useState} from "react";

function Tuition() {
    const [dataLocked, setDataLocked] = useState(true);
    const [password, setPassword] = useState("");
    const schoolArray = [
        {
            name: "Prodigy at Acadiana",
            files: [
                {name: "Infants", link: "acadiana/Acad Infants.pdf"},
                {name: "Ones", link: "acadiana/Acad Ones.pdf"},
                {name: "Pre-K", link: "acadiana/Acad PreK.pdf"},
                {name: "Twos", link: "acadiana/Acad Twos.pdf"},
                {name: "Threes", link: "acadiana/Acad Threes.pdf"},
            ]
        },
        {
            name: "Prodigy at Berkeley", files: [
                {name: "Nido", link: "Berkeley/Berk Nido.pdf"},
                {name: "Pre-K", link: "Berkeley/Berk Pre.pdf"},
            ]
        },
        {
            name: "Prodigy at Creekside", files: [
                {name: "Infants", link: "creekside/Cree Infants.pdf"},
                {name: "Nido", link: "creekside/Cree Nido.pdf"},
                {name: "Preschool", link: "creekside/Cree Preschool.pdf"},
                {name: "VPK", link: "creekside/Cree VPK.pdf"},
                {name: "Primary", link: "Primary Fees.pdf"},
            ]
        },
        {
            name: "Prodigy at Epperson", files: [
                {name: "Infants", link: "epperson/Epp Infants.pdf"},
                {name: "Nido", link: "epperson/Epp Nido.pdf"},
                {name: "Preschool", link: "epperson/Epp Preschool.pdf"},
                {name: "VPK", link: "epperson/Epp VPK.pdf"},
                {name: "Primary", link: "Primary Fees.pdf"},
            ]
        },
        // {name: "Prodigy at Keys Gate", files: []},
        {
            name: "Prodigy at Tapestry", files: [
                {name: "Infants", link: "tapestry/Tap Infants.pdf"},
                {name: "Nido", link: "tapestry/Tap Nido.pdf"},
                {name: "Preschool", link: "tapestry/Tap Pre.pdf"},
                {name: "Primary", link: "Primary Fees.pdf"},
            ]
        },
        {
            name: "Prodigy at Winthrop", files: [
                {name: "Infant", link: "winthrop/Win Infant.pdf"},
                {name: "Nido", link: "winthrop/Win Nido.pdf"},
                {name: "Preschool", link: "winthrop/Win Preschool.pdf"},
                {name: "VPK", link: "winthrop/Win VPK.pdf"},
                {name: "Primary", link: "Primary Fees.pdf"},
            ]
        },
        {
            name: "Prodigy at Wellen Park", files: [
                {name: "Infant", link: "wellen park/WP Infant.pdf"},
                {name: "Nido", link: "wellen park/WP Nido.pdf"},
                {name: "Preschool", link: "wellen park/WP Preschool.pdf"},
                {name: "VPK", link: "wellen park/WP VPK.pdf"},
                {name: "Primary", link: "Primary Fees.pdf"},
            ]
        },
        {
            name: "Prodigy at Wellington", files: [
                {name: "Infant", link: "wellington/Well Infant.pdf"},
                {name: "Nido", link: "wellington/Well Nido.pdf"},
                {name: "Preschool", link: "wellington/Well Preschool.pdf"},
                {name: "VPK", link: "wellington/Well VPK.pdf"},
                {name: "Primary", link: "Primary Fees.pdf"},
            ]
        },
    ];

    const handleChange = (event: { target: { name: string; value: string; }; }) => {
        const {value} = event.target;
       setPassword(value);
    };

    const handleSubmit = ()=>{
        if(password == 'tuition2024'){
            setDataLocked(false);
        }
    }

    {if(dataLocked){
      return (
          <Box sx={{}}>
              <ResponsiveAppBar/>
              <Box sx={{width: 300, margin: 'auto', pt: 20, pb: 20,}}>

                      <TextField
                          name="password"
                          label="Password"
                          value={password}
                          onChange={handleChange}
                          required
                          fullWidth

                          margin="normal"
                          sx={{textAlign: 'left', backgroundColor: 'rgba(255, 255, 255, 0.5)'}}
                      />
                      <Button type="submit" variant="contained" color="primary" onClick={handleSubmit}>
                          Submit
                      </Button>

              </Box>


              <Footer/>
          </Box>
      );
    }}
    return (
        <>
            <Box sx={{}}>
                <ResponsiveAppBar/>
                <Box sx={{pt:10, pb:5,}}><Link  href={`/rates/Pricing Cheat Sheet.pdf`}>Pricing Cheat Sheet</Link></Box>

                {schoolArray.map((item) => {
                    return <>
                        <Box sx={{fontWeight: 'bold', fontSize: 20, mt:0,}}>{item.name}</Box>
                        {item.files.map((fileName) => {
                            return <Box><Link href={`/rates/${fileName.link}`}>{fileName.name}</Link></Box>
                        })}
                        <Box sx={{width:100,m:'auto',}}>
                            <hr/>
                        </Box>
                    </>
                })}

                <ContactFooter/>
                <Footer/>
            </Box>
        </>
    );
}

export default Tuition

import React from 'react'
import ReactDOM from 'react-dom/client'
import OurStory from "./pages/OurStory.tsx";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import "@fontsource/montserrat/300.css";
import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/500.css";
import "@fontsource/montserrat/600.css";
import "@fontsource/montserrat/700.css";
import "@fontsource/montserrat/800.css";
import "@fontsource/montserrat/900.css";
import './App.css'
import Academics from "./pages/Academics.tsx";
import ArtsLabs from "./pages/ArtsLabs.tsx";
import OurSchools from "./pages/OurSchools.tsx";
import {Box, createTheme, ThemeProvider} from "@mui/material";
import Admissions from "./pages/Admissions.tsx";
import LetsTalk from "./pages/LetsTalk.tsx";
import backgroundImage from "./assets/background.png";

import './custom.scss';
import Tuition from "./pages/Tuition.tsx";

const router = createBrowserRouter([
    {
        path: "/",
        element: <OurStory/>,
    },
    {
        path: "/wellington-green",
        element: <OurStory/>,
    },
    {
        path: "/wc",
        element: <OurStory/>,
    },
    {
        path: "/academics",
        element: <Academics/>,
    },
    {
        path: "/arts-labs",
        element: <ArtsLabs/>,
    },
    {
        path: "/admissions",
        element: <Admissions/>,
    },
    {
        path: "/our-schools",
        element: <OurSchools/>,
    },
    {
        path: "/lets-talk",
        element: <LetsTalk/>,
    },
    {
        path: "/rate-data",
        element: <Tuition/>,
    },
    {
        path: "*",
        element: <OurStory/>,
    },
])

const theme = createTheme({
    typography: {
        fontFamily: [
            'Montserrat',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
});

ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <Box component={"img"} sx={{
                width: '100vw',
                height: '100vh',
                position: 'fixed',
                top: 0,
                left: 0,
                maxWidth: '100%',
                maxHeight: '100%',
                objectFit: 'cover',
                zIndex: '-2',
            }} src={backgroundImage} alt={"background"}></Box>
            <RouterProvider router={router}/>
        </ThemeProvider>
    </React.StrictMode>,
)

import {Box} from "@mui/material";
import artLab from '../assets/art-lab.png';
import culinaryLab from '../assets/culinary-lab.png';
import languageImmersion from '../assets/language-immersion.png';
import mindfulness from '../assets/mindfulness.png';

import ContentTile from "../assets/components/ContentTile.tsx";
import ResponsiveAppBar from "../assets/components/ResponsiveAppBar.tsx";
import ContactFooter from "./ContactFooter.tsx";
import Footer from "./Footer.tsx";

function ArtsLabs() {
    return (
        <>
            <Box sx={{}}>
                <ResponsiveAppBar/>
                <ContentTile
                    id={"art-lab"}
                    header={"ART LAB"}
                    subtitle={"Atelier"}
                    body={`The positive impact of art education on brain development, especially on the creative and intuitive right brain functions, has policymakers and educators looking more than ever to incorporate the arts into their curriculums. Rather than teaching the right answer, art learning promotes exploration and critical thinking, skills better developed as early as possible. Research has shown significant gains in math, reading, cognitive ability, critical thinking, and verbal skills in students involved in the arts when compared to students lacking art learning experiences. These gains, coupled with the ability of arts learning to improve motivation, teamwork, concentration, confidence and self- expression, makes the arts one of the most critical pieces of a child's early learning experience.
                            \\n\\n
                            Beyond the cognitive benefits of art learning at a young age, social scientists and psychologists have found instruction in the arts to have long lasting benefits to social behaviors, social compliance, ability to express emotion, courtesy, tolerance, conflict-resolution skills, and the ability to collaborate. Learning how to cooperate and be tolerant as a means to an end instills in children an understanding of working with others that will have benefits beyond classroom experiences and art practices. These cooperative skills will aid them for years to come in their professional and family lives.`}
                    ImageComponent={<img src={artLab} alt={'Art Lab'}/>}
                    reverse={true}
                />

                <ContentTile
                    id={"culinary-lab"}
                    header={"CULINARY LAB"}
                    subtitle={""}
                    body={`The Culinary Lab is a core component of our curriculum. Labs offer an early opportunity for students to build a foundation of healthy and natural eating while engrossing them in the scientific wonders that can be found in a kitchen.
\\n\\n
Children utilize different ingredients each week depending on the lab's recipe and often incorporate the herbs and vegetables grown on campus as a part of the children’s gardening activities; it is the ultimate “farm to table” experience! At the youngest of ages the Culinary Lab offers an opportunity to work on Fine Motor Skills and engage in a truly sensorial experience. As the children progress through school the labs become more sophisticated and include a study of the culture behind the international cuisine and ingredients, as well as a unique platform to study math and science.`}
                    ImageComponent={<img src={culinaryLab} alt={'Culinary Lab'}/>}
                />
                <ContentTile
                    id={"language-immersion"}
                    header={"LANGUAGE IMMERSION"}
                    subtitle={"Benefits of Bilingualism"}
                    body={`The benefits of introducing our children to a second language from the youngest of ages not only include extensive cognitive advantages but also make the learning process more impactful. At this age children are often learning words for objects for the first time in their native language. With the introduction of a second language, children are given two words for every object or action, exponentially increasing their mental flexibility and deepening their retention abilities. Introducing the concept of multiple correct answers for a situation provides the foundation for the development of creative problem solving skills. The brain's so called "executive function” is exponentially more developed in bilinguals, providing them with a heightened ability to focus, handle multiple tasks efficiently and problem solve.`}
                    ImageComponent={<img src={languageImmersion} alt={'Language Immersion'}/>}
                    reverse={true}
                />
                <ContentTile
                    id={"mindfulness"}
                    header={"MINDFULNESS"}
                    subtitle={"The Child's Path"}
                    body={`Yoga, the ancient eastern practice of bringing union to the body, mind and spirit, is an invaluable part of the physical development of our children at. Physically, it enhances their flexibility, strength, coordination, and body awareness. In addition, their concentration and sense of calmness and relaxation improves. By doing yoga, children exercise, play, and connect more deeply with the inner self. This in turn allows them to develop a stronger relationship with the natural world that surrounds them.`}
                    ImageComponent={<img src={mindfulness} alt={'Mindfulness'}/>}
                />
                <ContactFooter/>
                <Footer/>
            </Box>
        </>
    );
}

export default ArtsLabs

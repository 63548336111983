import {Box} from "@mui/material";
import heroMovie from '../assets/hero/movie.mp4'
import ourApproach from '../assets/our-approach.png';
import familyEngagement from '../assets/family-engagement.png';
// import accreditation from '../assets/accreditation.png';
import logo from '../assets/logo-wide.svg';
import flare from '../assets/flare/rip-top.svg'

import ContentTile from "../assets/components/ContentTile.tsx";
import ResponsiveAppBar from "../assets/components/ResponsiveAppBar.tsx";
import Footer from "./Footer.tsx";
import ContactFooter from "./ContactFooter.tsx";

function OurStory() {
    return (
        <>
            <Box sx={{}}>
                <ResponsiveAppBar/>
                <Box className={'hero'}>
                    <Box sx={{maxHeight: {xs: '70vh', sm: '90vh'}, display:'flex', justifyContent:'center', placeItems:'baseline', alignItems:'center', overflow:'hidden'}}>
                        <video className={'hero-video'} autoPlay style={{ minHeight:'50vh'}} playsInline loop muted>
                            <source src={heroMovie} type="video/mp4"/>
                            Your browser does not support the video tag.
                        </video>
                        <Box sx={{position:'absolute', top:0, left:0, width:'100vw', height:'100vh', background:'black', opacity:'0.2'}}></Box>
                    </Box>
                    <Box sx={{position:'absolute', top:0, left:0, width:'100%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center', overflow:'hidden'}}>
                        <img style={{width:'80%', maxWidth:'330px'}} src={logo} alt={"Prodigy Learning"}/>
                    </Box>
                    <Box component={"img"} sx={{position:'absolute', 'bottom':-1, 'left':-10, width:{xs: '200vw', sm: '150vw', md:'100vw'}, objectFit:'cover', maxHeight:200, objectPosition:'top',}} alt={"ripped paper"} src={flare} />
                </Box>
                <ContentTile
                    id={"our-approach"}
                    header={"OUR APPROACH"}
                    subtitle={"Reggio Inspired Learning"}
                    body={`Prodigy Early Learning Centers are modeled on the
                        developmental and
                        constructivist approaches inspired by the work of the most influential child
                        psychologists in history: Maria Montessori, Jean Piaget, Lev Vygotsky, and Loris
                        Malaguzzi. We are committed to the development of the whole child, instilling in
                        them a
                        robust sense of self-confidence to navigate new experiences and the challenges of a
                        rapidly evolving world.
                        \\n\\n
                        In the constructivist approach to teaching and learning, children are viewed as
                        unique
                        learners. Educators focus on observing and listening to children to understand their
                        interests, needs, and learning styles which enables teachers to tailor learning
                        experiences to the unique abilities and preferences of each child.`}
                    ImageComponent={<img src={ourApproach} alt={'Our Approach'}/>}
                    reverse={true}
                />

                <ContentTile
                    id={"learning-environment"}
                    header={"LEARNING ENVIRONMENT"}
                    subtitle={""}
                    body={`Our learning environments are recognized as the third teacher and considered an 
                    essential aspect of learning. Classrooms are carefully curated to be inviting, flexible, and
                     organized, promoting exploration and collaboration. Natural materials, open spaces, and purposeful 
                     displays stimulate curiosity and creativity. Teachers act as guides allowing children to solve problems
                      and navigate the different aspects of project-based lessons in their environments. Observing children
                       in action gives teachers perspective into the students’ strengths, areas in need of improvement, 
                       emotional development, and their likes and dislikes.`}
                    isSlideshow={true}
                    ImageComponent={<div></div>}
                />
                <ContentTile
                    id={"family-engagement"}
                    header={"FAMILY ENGAGEMENT"}
                    subtitle={"Building School Community"}
                    body={`The foundation of our philosophy and curriculum is built upon the partnership of family, 
                    community, and school. Involving children in school-wide events and celebrating their work throughout 
                    the larger school community exposes them to a broader scope of teachers and role models. It instills 
                    and emphasizes a sense of community and belonging from a young age. Celebrating their work and achievements 
                    also serves to underscore the importance and value of education in their lives and encourage them to
                     pursue new goals.
                        \\n\\n
                        Prodigy Early Learning takes pride in building meaningful relationships with our families. On 
                        several special occasions throughout the year, we host community events open to parents and 
                        families, which strengthens the link between children’s school lives and their support systems 
                        outside of the educational realm.`}
                    ImageComponent={<img src={familyEngagement} alt={'Family Engagement'}/>}
                    reverse={true}
                />
                {/*<ContentTile*/}
                {/*    id={"accreditation"}*/}
                {/*    header={"ACCREDITATION"}*/}
                {/*    subtitle={"Cognia"}*/}
                {/*    body={`All Prodigy Early Learning Centers are accredited by Cognia, the largest education improvement */}
                {/*    organization in the world. For more than 125 years, Cognia and its legacy agencies have provided a */}
                {/*    global network to strengthen and support schools through accreditation and certification, assessment, */}
                {/*    professional learning, and customized improvement services. With performance standards revisited and */}
                {/*    updated every six years, Cognia ensures schools maintain the highest quality of care and excellence.`}*/}
                {/*    ImageComponent={<img src={accreditation} alt={'Accreditation'}/>}*/}
                {/*/>*/}
                <ContactFooter/>
                <Footer/>


            </Box>


        </>
    );
}

export default OurStory
